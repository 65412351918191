import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Weather from './weather'; // Assuming this is a page for weather
import Homee from './home'; // Assuming this is your homepage
import Login from './login'; // Login page component
import Navbar from './navbar'; // Make sure to import your Navbar component
import Register from './registration';
import HomePage from './dashboard';

function App() {
    return (
        <div>
            <BrowserRouter>
                {/* Always render Navbar */}
                <Routes>
                    <Route path="/weather" element={<Weather />} />
                    <Route path="/" element={<Homee />} />
                    <Route path="/login" element={<Login />} /> 
                    <Route path="/register" element={<Register />} />
                    <Route path="/dashboard" element={<HomePage />} />  {/* Route to login page */}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;