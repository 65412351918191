import React, { useEffect, useRef } from 'react';
import Navbar from './navbar';
import './navbar.css';
import './home.css';


function Homee() {
  const cardRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.5 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, []);
  

  return (
    <>
      <Navbar />
      <div className="hospital-management-container">
        <div className="header-section">
          <div className="header-image" >
            <img
              src="https://images.pexels.com/photos/6129043/pexels-photo-6129043.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt="Hospital building or healthcare scene"
              className="hospital-image"
            />
          </div>
          <div className="content-and-logo">
            <div className="content">
              <h1 className="main-heading" style={{color:'#D9EAFD'}}>Welcome to Our Hospital</h1>
              <p className="description" style={{ fontSize: '20px', color: 'white' }}>
  <span style={{ display: 'block' }}>
    Delivering exceptional healthcare services through state-of-the-art technology and dedicated care teams.
  </span>
  <span style={{ display: 'block' }}>
    Our mission is to improve lives and promote wellness.
  </span>
  <span style={{ display: 'block' }}>
    Join us in advancing healthcare to new heights.
  </span>
</p>
              
              <button className="learn-more-button" onClick={() => alert('Learn More')}>
                Learn More
              </button>
            </div>
            <div className="logo-container">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="logo-svg" style={{ stroke: 'white', fill: 'none' }}>
                <polygon className="logo-polygon" points="100,10 190,60 155,180 45,180 10,60" style={{ stroke: 'white', fill: 'none' }} />
              </svg>
              <p className="logo-description">Your trusted partner in healthcare innovation</p>
            </div>
          </div>
        </div>



        <section className="quick-links-section">
  <h2>Quick Links</h2>
  <div className="quick-links-container">
    <div className="quick-link-item">Centre Of Excellence</div>
    <div className="quick-link-item">Book An Appointment</div>
    <div className="quick-link-item">Insurance</div>
    <div className="quick-link-item">Physiotherapy</div>
    <div className="quick-link-item">Health Checkup Packages</div>
    <div className="quick-link-item">Video Consultation</div>
    <div className="quick-link-item">Direct Numbers & Departments</div>
    <div className="quick-link-item">Find a Doctor</div>
    <div className="quick-link-item">Gynecology</div>
    <div className="quick-link-item">Orthopedics</div>
    <div className="quick-link-item">Cardiology & Cardiac Surgery</div>
    <div className="quick-link-item">Oncology</div>
    <div className="quick-link-item view-more">View More</div>
  </div>
</section>

        <section className="content-section">
          <div className="content-wrapper">
            <div className="text-content">
              <h1 className="main-heading">Our Expertise For Your Service</h1>
              <p className="tagline">Comprehensive Care. Advanced Technology.</p>
              <p className="description">
                Empowering healthcare providers with innovative solutions to improve patient care and streamline hospital operations.
              </p>
              <div className="search-section">
                <input type="text" placeholder="Search for doctors, departments, or services..." className="search-input" />
                <button className="search-button" onClick={() => alert('Search initiated!')}>
                  Search
                </button>
              </div>
            </div>
            <div className="image-content">
              <img
                src="https://images.pexels.com/photos/4586993/pexels-photo-4586993.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt="Hospital related visual"
                className="hospital-image"
              />
            </div>
          </div>
        </section>

        <section className="features-section">
          <div className="features-header">
            <h2>Hospital System Features</h2>
            <p>Empowering healthcare management with cutting-edge solutions</p>
          </div>
          <div className="features-container">
            <div className="feature-card">
              <div className="icon-container">
                <i className="fas fa-user-md"></i>
              </div>
              <h3>Doctor Portal</h3>
              <p>Centralized management for schedules, patient notes, and follow-ups.</p>
            </div>
            <div className="feature-card">
              <div className="icon-container">
                <i className="fas fa-hospital-user"></i>
              </div>
              <h3>Patient Dashboard</h3>
              <p>Comprehensive access to medical history, test results, and prescriptions.</p>
            </div>
            <div className="feature-card">
              <div className="icon-container">
                <i className="fas fa-chart-bar"></i>
              </div>
              <h3>Analytics</h3>
              <p>Detailed insights into hospital operations and patient care trends.</p>
            </div>
          </div>
        </section>

        {/* Team Section */}
        <section className="team-section">
          <h2>Meet Our Expert Team</h2>
          <div className="team-container">
            <div className="team-member">
              <img src="https://tse1.mm.bing.net/th?id=OIP.BvsqmgN4Hd9bLUshBWYRBgHaFE&pid=Api&P=0&h=180" alt="Team Member" />
              <h4>Dr. Emily Stevens</h4>
              <p>Chief Medical Officer</p>
            </div>
            <div className="team-member">
              <img src="https://tse3.mm.bing.net/th?id=OIP.IVwf85npYYUcwRp4EIhqDgHaJm&pid=Api&P=0&h=180" alt="Team Member" />
              <h4>John Smith</h4>
              <p>Operations Manager</p>
            </div>
            <div className="team-member">
              <img src="https://tse2.mm.bing.net/th?id=OIP.APjmKmC7pAwcvBCbKoxVmgHaGO&pid=Api&P=0&h=180" alt="Team Member" />
              <h4>Sarah Lee</h4>
              <p>Lead Software Engineer</p>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <div className="testimonials">
          <h2>What Our Users Say</h2>
          <div className="testimonial-slider">
            <div className="testimonial">
              <p>
                "The hospital system has transformed the way we manage patients and operations. It's intuitive and highly efficient!"
              </p>
              <h4>- Dr. Sarah Johnson</h4>
            </div>
            <div className="testimonial">
              <p>
                "From billing to inventory, everything is streamlined. The system saves us time and ensures accuracy."
              </p>
              <h4>- Mark Lee, Admin</h4>
            </div>
          </div>
        </div>

        {/* Call-to-Action Section */}
        <div className="cta-section">
          <h2>Ready to Elevate Your Hospital Management?</h2>
          <p>Contact us today to get a personalized demo and see how our system can benefit your hospital.</p>
          <button className="cta-button">Get Started</button>
        </div>

        {/* Accordion FAQ Section */}
        <section className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="accordion">
            <div className="accordion-item">
              <div className="accordion-header">What features are available?</div>
              <div className="accordion-content">
                Our hospital management system offers doctor portals, patient dashboards, and comprehensive analytics tools.
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header">How can I get a demo?</div>
              <div className="accordion-content">
                You can contact us through our website to schedule a personalized demo.
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header">What is the pricing structure?</div>
              <div className="accordion-content">
                Our pricing is based on your hospital's size and needs. Contact us for more details.
              </div>
            </div>
          </div>
        </section>

        {/* Contact Form */}
        <section className="contact-section">
          <h2>Contact Us</h2>
          <form>
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <textarea placeholder="Your Message" required></textarea>
            <button type="submit" className="contact-button">Send Message</button>
          </form>
        </section>

        {/* Footer Section */}
        <footer className="footer">
          <div className="footer-links">
            <a href="#">Privacy Policy</a>
            <a href="#">Terms of Service</a>
            <a href="#">Contact Us</a>
          </div>
          <div className="social-links">
            <a href="#"><i className="fab fa-facebook-f"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin-in"></i></a>
          </div>
          <p>&copy; 2024 HealthCare Inc. All rights reserved.</p>
        </footer>
      </div>
    </>
  );
}

export default Homee;