import React, { useState } from 'react';
import './login.css';  // Import the CSS file for styling
import Navbar from './navbar'; // Assuming you want to include Navbar here as well

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Please fill in all fields');
    } else {
      setError('');
      alert('Login successful!');
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-page">
        <div className="login-box">
          <div className="login-content">
            <div className="left-side">
              <img
                src="https://cdn.pixabay.com/photo/2021/11/20/03/35/doctor-6810776_640.png" // Replace with your image URL
                alt="Login Illustration"
                className="login-image"
                style={{height:'300px'}}
              />
            </div>
            <div className="right-side">
             

              {/* Motivational Quote */}
              <div className="quote-container">
                <p className="quote-text">
                 
                </p>
              </div>

              {error && <p className="error-message">{error}</p>}

              <form onSubmit={handleSubmit}>
              <h2>Login</h2>
                {/* Email Input */}
                <div className="input-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>

                {/* Password Input */}
                <div className="input-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                </div>

                {/* Submit Button */}
                <button type="submit" className="login-btn">
                  Login
                </button>

                {/* Footer Links */}
                <div className="footer-links">
                  <a href="/forgot-password">Forgot password?</a>
                  <p>
                    Don't have an account? <a href="/register">Register</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;