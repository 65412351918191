import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './navbar.css';

function Navbar() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <nav className="navbar">
      {/* Left side: Logo */}
      <div className="navbar-left">
        <img style={{height:'50px'}} src="https://tse1.mm.bing.net/th?id=OIP.HGSfo-iDIsPNzQ0EfIOkOQHaE8&pid=Api&P=0&h=180" alt="Logo" className="logo" />
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="navbar-hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Mobile Menu */}
      <div className={`navbar-menu ${menuActive ? 'active' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link>
        <Link to="dashboard" onClick={toggleMenu}>DashBoard</Link>
        <Link to="/login" onClick={toggleMenu}>Login</Link>
        <Link to="/register" onClick={toggleMenu}>Sign Up</Link> {/* Added Sign Up link */}

        {/* Search and Location buttons inside the mobile menu */}
        <button className="navbar-btn">
          <img src="https://cdn-icons-png.flaticon.com/128/954/954591.png" alt="Search" className="navbar-icon" />
        </button>
        <button className="navbar-btn">
          <img src="https://tse2.mm.bing.net/th?id=OIP.k5-_nx0bHpaT7ZBZTHlPnwAAAA&pid=Api&P=0&h=180" alt="Location" className="navbar-icon" />
        </button>
      </div>

      {/* Desktop/Menu Links (Always visible) */}
      <div className="navbar-links">
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/dashboard">DashBoard</Link></li>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/register">Sign Up</Link></li> {/* Added Sign Up link */}
        </ul>
        <div className="navbar-right" style={{ display: 'flex', gap: '10px' }}>
  <button
    className="navbar-btn"
    style={{
      height: 'auto',
      width: 'auto',
      background: 'none',
      border: 'none',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }}
  >
    <img
      src="https://cdn-icons-png.flaticon.com/128/954/954591.png"
      alt="Search"
      style={{
        height: '20px',
        width: 'auto',
        objectFit: 'contain',
      }}
    />
  </button>
  <button
    className="navbar-btn"
    style={{
      height: 'auto',
      width: 'auto',
      background: 'none',
      border: 'none',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }}
  >
    
  </button>
</div>
</div>
    </nav>
  );
}

export default Navbar;