import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './sidebar.css'; // Make sure the path is correct

function Sidebar() {
  const [menuActive, setMenuActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false); // State for dropdown visibility

  const toggleMenu = () => {
    setMenuActive(!menuActive); // Toggle sidebar visibility
  };

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive); // Toggle dropdown visibility
  };

  // Inline style for sidebar background color
  const sidebarStyle = {
    backgroundColor: '#BCCCDC', // Change this to any color you prefer
  };

  return (
    <div className="sidebar-container">
      {/* Hamburger Icon for Mobile */}
      <div className="sidebar-hamburger" style={{ color: 'black' }} onClick={toggleMenu}>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
      </div>

      {/* Sidebar Menu */}
      <div
        className={`sidebar ${menuActive ? 'active' : ''}`}
        style={sidebarStyle} // Apply inline style here
      >
        {/* Hospital Logo and Name */}
        <div className="sidebar-header">
          <img
            src="https://tse1.mm.bing.net/th?id=OIP.HGSfo-iDIsPNzQ0EfIOkOQHaE8&pid=Api&P=0&h=180"
            alt="Hospital Logo"
            className="hospital-logo"
          />
          <h1 className="hospital-name">City Hospital</h1>
        </div>

        {/* Sidebar Links */}
        <div className="sidebar-menu" style={{ color: 'black' }}>
          <Link to="/" onClick={toggleMenu}>Home</Link>
          <Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link>
          <Link to="/login" onClick={toggleMenu}>Login</Link>
          <Link to="/register" onClick={toggleMenu}>Sign Up</Link>

          {/* Dropdown Toggle Button */}
          <div className="dropdown" onClick={toggleDropdown}>
            <span>More Options</span>
            <i className={`arrow ${dropdownActive ? 'rotate' : ''}`}></i>
          </div>

          {/* Dropdown Content */}
          {dropdownActive && (
            <div className="dropdown-content">
              <Link to="/profile" onClick={toggleMenu}>Profile</Link>
              <Link to="/settings" onClick={toggleMenu}>Settings</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;