import React, { useState } from 'react';
import './registration.css'; // Import the CSS file for styling
import Navbar from './navbar'; // Include Navbar if required

function Register() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = formData;

    if (!name || !email || !password || !confirmPassword) {
      setError('Please fill in all fields');
    } else if (password !== confirmPassword) {
      setError('Passwords do not match');
    } else {
      setError('');
      alert('Registration successful!');
    }
  };

  return (
    <>
      <Navbar />
      <div className="register-page">
        <div className="register-box">
          <div className="register-content">
            <div className="left-side">
              <img
                src="https://cdn.pixabay.com/photo/2020/08/03/09/45/medical-5459661_640.png" // Replace with your image URL
                alt="Register Illustration"
                className="register-image"
                style={{ height: '300px' }}
              />
            </div>
            <div className="right-side">
              {error && <p className="error-message">{error}</p>}
              <form onSubmit={handleSubmit}>
                <h2>Register</h2>

                {/* Name Input */}
                <div className="input-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                    required
                  />
                </div>

                {/* Email Input */}
                <div className="input-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                  />
                </div>

                {/* Password Input */}
                <div className="input-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    required
                  />
                </div>

                {/* Confirm Password Input */}
                <div className="input-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    placeholder="Confirm your password"
                    required
                  />
                </div>

                {/* Submit Button */}
                <button type="submit" className="register-btn">
                  Register
                </button>

                {/* Footer Links */}
                <div className="footer-links">
                  <p>
                    Already have an account? <a href="/login">Login</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;