import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './sidebar'; // Ensure correct path to Sidebar component
import './dashboard.css'; // Make sure you import the CSS file
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// Register necessary components from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,   // Register LineElement for line charts
  PointElement   // Register PointElement for scatter and line charts
);

const HomePage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar is initially closed on mobile
  const chartRef = useRef(null); // Chart reference to manage cleanup

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen); // Toggle sidebar visibility
  };

  // Sample data for the Bar Chart
  const barChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Admissions Data',
        data: [120, 150, 130, 170, 160, 180],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Options for the Bar Chart
  const barChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Monthly Hospital Admissions Data',
      },
    },
  };

  // Table data for Patient Information
  const patientData = [
    { id: 1, name: 'John Doe', age: 28, department: 'Cardiology', appointment: '2024-12-06' },
    { id: 2, name: 'Jane Smith', age: 34, department: 'Neurology', appointment: '2024-12-10' },
    { id: 3, name: 'Michael Johnson', age: 25, department: 'Orthopedics', appointment: '2024-12-08' },
    { id: 4, name: 'Emily Davis', age: 22, department: 'Pediatrics', appointment: '2024-12-12' },
  ];

  // Cleanup Chart instance when component unmounts or updates
  useEffect(() => {
    const chartInstance = chartRef.current?.chartInstance;
    return () => {
      if (chartInstance) {
        chartInstance.destroy(); // Clean up the previous chart instance
      }
    };
  }, []);

  return (
    <div className="homepage-container" >
      {/* Sidebar */}
      <Sidebar className='toggle-button' open={sidebarOpen}  toggleSidebar={toggleSidebar} />

      {/* Main Content */}
      <div className="main-content" >
        <div className="polygon-background" ></div> {/* Polygon background */}
        <h1 className="main-title" >Welcome to the Hospital Management System</h1>
        <p className="dashboard-description" style={{ padding: '10px' }}>
          Manage patient records, appointments, and more with ease. Use the features below to get started.
        </p>

        {/* Hospital Features Section */}
        




        <div className="features-and-announcements-container" >
  {/* Key Features */}
  <div className="hospital-features" style={{backgroundColor:'#D9EAFD'}}>
    <h2>Key Features</h2>
    <ul>
      <li>Manage patient information and medical history</li>
      <li>Schedule and track appointments</li>
      <li>Track hospital admissions and discharges</li>
      <li>Monitor staff and doctor availability</li>
      <li>Generate medical reports and statistics</li>
    </ul>
  </div>

  {/* Announcements */}
  <div className="announcements" style={{backgroundColor:'#D9EAFD'}}>
    <h2>Announcements</h2>
    <ul>
      <li>New COVID-19 guidelines implemented from December 1st.</li>
      <li>Annual health check-up packages are now available.</li>
      <li>Blood donation camp scheduled on December 15th.</li>
      <li>New pediatric wing inaugurated on November 25th.</li>
      <li>Staff meeting on December 10th at 2 PM.</li>
    </ul>
  </div>
</div>

<div className="doctor-availability" style={{marginTop:'10px'}}>
  <h3>Doctor Availability</h3>
  <table>
    <thead style={{backgroundColor:'#007BFF'}}>
      <tr>
        <th style={{backgroundColor:'#D9EAFD'}}>Doctor</th>
        <th style={{backgroundColor:'#D9EAFD'}}>Specialty</th>
        <th style={{backgroundColor:'#D9EAFD'}}>Availability</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Dr. John Doe</td>
        <td>Cardiology</td>
        <td>Mon, Wed, Fri</td>
      </tr>
      <tr>
        <td>Dr. Jane Smith</td>
        <td>Neurology</td>
        <td>Tue, Thu, Sat</td>
      </tr>
    </tbody>
  </table>
</div>

        {/* Bar Chart and Calendar in the same row */}
        <div className="charts-and-calendar-container">
          <div className="chart-container">
            <Bar data={barChartData} options={barChartOptions} ref={chartRef} />
          </div>
          <div className="calendar-container">
            <Calendar />
          </div>
        </div>

        {/* Patient Information Table */}
        <div className="patient-table-container">
          <h3>Patient Information</h3>
          <table>
            <thead>
              <tr>
                <th style={{backgroundColor:'#D9EAFD'}}>ID</th>
                <th style={{backgroundColor:'#D9EAFD'}}>Name</th>
                <th style={{backgroundColor:'#D9EAFD'}}>Age</th>
                <th style={{backgroundColor:'#D9EAFD'}}>Department</th>
                <th style={{backgroundColor:'#D9EAFD'}}>Appointment Date</th>
              </tr>
            </thead>
            <tbody>
              {patientData.map((patient) => (
                <tr key={patient.id}>
                  <td>{patient.id}</td>
                  <td>{patient.name}</td>
                  <td>{patient.age}</td>
                  <td>{patient.department}</td>
                  <td>{patient.appointment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Department Overview */}
        <div className="department-overview" style={{backgroundColor:'#D9EAFD'}}>
          <h3>Hospital Departments</h3>
          <ul>
            <li><strong>Cardiology:</strong> Heart-related health issues, treatments, and surgeries.</li>
            <li><strong>Neurology:</strong> Brain and nervous system care, including surgery and diagnostics.</li>
            <li><strong>Orthopedics:</strong> Musculoskeletal system treatments, including fractures and joint replacements.</li>
            <li><strong>Pediatrics:</strong> Child health, growth monitoring, and developmental issues.</li>
            <li><strong>Oncology:</strong> Cancer detection, prevention, and treatment.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomePage;