import React, { useState } from "react";
import {useEffect , useRef} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Weather() {

  const [inputvalue , setinputvalue] = useState('')
  //console.log("**input value**", inputvalue)
  const [data , setdata] = useState()
  const inputref = useRef(null)
   
  const getWeather = () =>{
   console.log('first')
   console.log(inputref.current.value)
   inputref.current.type = "checkbox"
   /*
   axios.get(`http://api.weatherapi.com/v1/current.json?key=4ce23bfe00784eb5a5765117241705&q=${inputref.current.value}&aqi=no`).
   then((res) => {
    console.log(res.data)
    setdata(res.data)
   })
   .catch((err)=>{
    console.log(err.response.data.error.message)
    if(err.response.data)
        {
            toast.error('There is no loction in this name')
        }
   })
   
*/
  }

 //useEffect(() =>{
    
 //   getWeather()

 //   },[])

//const HandleChange = (e) =>{
//    console.log(e.target.value)
 //   setinputvalue(e.target.value)
//}

    return (
        <>
     <input type="text" ref={inputref} />
     <button type="submit" onClick={getWeather}>submit</button> <ToastContainer />
     { data? 
       <div>
        <p>{data.current.temp_c}</p>
        </div>
     :
     <h1>NO LOCATION IS PROVIDED</h1>
     }

        </>
    );
}

export default Weather;